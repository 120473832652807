const dataLayer = window.dataLayer || [];

export const trackAppliedFiltersCatalogs = ( catalogsForm ) => {
	if ( catalogsForm ) {
		const filtersChecked = catalogsForm.querySelectorAll( '.m-checkbox__input:checked' );

		if ( filtersChecked.length === 0 ) {
			return false;
		}

		if ( filtersChecked.length === 2 ) {
			let isDefaultTypeSelected = false;
			let isDefaultLanguageSelected = false;
			for ( const filterChecked of filtersChecked ) {
				if ( filterChecked.name !== 'type' && filterChecked.name !== 'language' ) {
					break;
				}

				if ( filterChecked.name === 'type' ) {
					if ( filterChecked.value === 'Catalog' ) {
						isDefaultTypeSelected = true;
					}
				}

				if ( filterChecked.name === 'language' ) {
					if ( filterChecked.value === 'English' ) {
						isDefaultLanguageSelected = true;
					}
				}
			}

			if ( isDefaultTypeSelected && isDefaultLanguageSelected ) {
				return false;
			}
		}

		const catalogsFilters = [];
		filtersChecked.forEach( ( catalogFilter ) => {
			catalogsFilters.push( catalogFilter.getAttribute( 'data-dl-filter' ) );
		} );
		dataLayer.push( {
			event: 'add_filter',
			search_type: 'catalog',
			filter: catalogsFilters,
		} );
	}
};

export const trackCompareBearings = () => {
	dataLayer.push( {
		event: 'compare',
		item: window._timken.compareBearingsData,
	} );
};

const trackGlobalSearch = () => {
	const aspMainContainer = document.querySelector( '.asp_main_container' );
	if ( ! aspMainContainer ) {
		return;
	}
	aspMainContainer.addEventListener( 'asp_results_show', function( resultsDetails ) {
		const searchResults = document.getElementsByClassName( 'js-search-results' )[ 0 ];
		const partsResults = searchResults ? Number( searchResults.getAttribute( 'data-parts-results' ) ) : 0;
		const catalogsResults = searchResults ? Number( searchResults.getAttribute( 'data-catalogs-results' ) ) : 0;
		const totalResults = partsResults + catalogsResults;
		const event = totalResults > 0 ? 'successful_search' : 'unsuccessful_search';
		dataLayer.push( {
			event,
			search_type: 'onsite',
			number_of_results: `${ partsResults }|${ catalogsResults }|${ totalResults }`,
		} );
	} );
};

const trackOffRampLink = () => {
	document.addEventListener( 'click', ( event ) => {
		const $target = event.target;

		const $link = $target.hasAttribute( 'data-link-click' ) ? $target : $target.closest( '[data-link-click]' );

		if ( $link ) {
			const text = $link.text?.trim();

			dataLayer.push( {
				event: 'off_ramp_selection',
				off_ramp_link: text,
			} );
		}
	} );
};

export const trackPDPContentLink = () => {
	const $links = document.querySelectorAll( '.t-parts-details__body a' );

	$links.forEach( ( link ) => {
		link.addEventListener( 'click', () => {
			const text = link.textContent?.trim();
			const url = link.getAttribute( 'href' );

			dataLayer.push( {
				event: 'eng_pdp_content_click',
				link_text: text,
				link_url: url,
			} );
		} );
	} );
};

export const trackPDPDetailsHeadLink = () => {
	const $resultActions = document.querySelector( '.t-parts-details__actions' )?.querySelectorAll( '.js-share-cta, .js-print-cta' );

	if ( $resultActions ) {
		$resultActions.forEach( ( action ) => {
			action.addEventListener( 'click', () => {
				const event = action.classList.contains('js-share-cta') ? 'pdp_email_results' : 'pdp_print';
				dataLayer.push( {
					event
				} );
			} );
		} );
	}
};
export const trackHomepageContentLink = () => {
	const $containers = document.querySelectorAll(
		'.js-start-search-new, .js-start-search-saved, .js-popular-tools, .js-eng-tools',
	);

	$containers.forEach( ( section ) => {
		section.addEventListener( 'click', ( event ) => {
			const { target, currentTarget } = event;
			const $link = target.matches( 'a' ) ? target : target.closest( 'a' );
			const subheading = currentTarget.querySelector( 'h3:not(.d-none)' )?.textContent;

			if ( $link ) {
				const text = $link.text?.trim();
				const url = $link.getAttribute( 'href' );

				dataLayer.push( {
					event: 'eng_homepage_content_click',
					subheading,
					link_text: text,
					link_url: url,
				} );
			}
		} );
	} );
};

export const trackLandingPageLink = () => {
	const $links = document.querySelectorAll( 'article.page a' );

	$links.forEach( ( link ) => {
		link.addEventListener( 'click', () => {
			const text = link.textContent?.trim();
			const url = link.getAttribute( 'href' );

			dataLayer.push( {
				event: 'eng_content_click',
				link_text: text,
				link_url: url,
			} );
		} );
	} );
};

export const trackBannerLink = () => {
	const topNavLinks = document.querySelectorAll( '.m-header__utility-nav a' );

	if ( topNavLinks ) {
		topNavLinks.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;
				const url = event.currentTarget.getAttribute( 'href' );

				dataLayer.push( {
					event: 'top_navigation_ribbon_click',
					nav_link_name: name,
					link_url: url,
				} );
			} );
		} );
	}
};

const trackSocialFlowEvent = () => {
	const socialLink = document.querySelectorAll( '#menu-social a' );

	if ( socialLink ) {
		socialLink.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;

				dataLayer.push( {
					event: 'social_follow',
					social_network: name,
				} );
			} );
		} );
	}
};

const trackEngineeringToolAction = () => {
	const $resultActions = document
		.querySelector( '.js-engineering-tools-result-actions' )
		?.querySelectorAll( '.js-share-cta, .js-print-cta' );

	if ( $resultActions ) {
		$resultActions.forEach( ( action ) => {
			action.addEventListener( 'click', () => {
				const event = action.getAttribute( 'data-dl-event' );
				const name = action.getAttribute( 'data-tool' );
				const item = JSON.parse( action.getAttribute( 'data-bearing' ) );

				dataLayer.push( {
					event,
					et_name: name,
					item,
				} );
			} );
		} );
	}
};

const trackTopNavigationEvent = () => {
	const utilityAndPrimaryLinks = document.querySelectorAll(
		'#utility-menu a, #primary-menu > .menu-item:not(.menu-item-has-children) a',
	);

	if ( utilityAndPrimaryLinks ) {
		utilityAndPrimaryLinks.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;
				dataLayer.push( {
					event: 'top_navigation_click',
					nav_link_name: name,
				} );
			} );
		} );
	}

	const submenuLinks = document.querySelectorAll( '#primary-menu .menu-item-has-children .sub-menu a' );

	if ( submenuLinks ) {
		submenuLinks.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;
				const parent = event.currentTarget.closest( '.menu-item-has-children' ).querySelector( 'a' ).text;
				dataLayer.push( {
					event: 'top_navigation_click',
					nav_link_name: `${ parent }|${ name }`,
				} );
			} );
		} );
	}

	const logoLink = document.querySelector( '.m-header__logo a' );
	if ( logoLink ) {
		logoLink.addEventListener( 'click', () => {
			dataLayer.push( {
				event: 'top_navigation_click',
				nav_link_name: 'Timken logo',
			} );
		} );
	}

	const helpButtonLink = document.querySelector( '.m-header__navigation-help-button' );
	if ( helpButtonLink ) {
		helpButtonLink.addEventListener( 'click', ( event ) => {
			const name = event.currentTarget.text;
			dataLayer.push( {
				event: 'top_navigation_click',
				nav_link_name: name.trim(),
			} );
		} );
	}
};

const trackFooterNavigationEvent = () => {
	const footerLinks = document.querySelectorAll( '#menu-footer a' );

	if ( footerLinks ) {
		footerLinks.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;

				dataLayer.push( {
					event: 'footer_navigation_click',
					nav_link_name: name,
				} );
			} );
		} );
	}
};

const trackDownloadEvent = () => {
	document.addEventListener( 'click', ( event ) => {
		const isLiteratureLink = event.target.closest( '.m-filtered-grid__entry-link--literature' );
		if ( isLiteratureLink ) {
			const url = isLiteratureLink.getAttribute( 'href' ) ?? '';
			dataLayer.push( {
				event: 'download_file',
				download_url: url,
			} );
		}
	} );
};

const trackHeroClickEvent = () => {
	const heroLinks = document.querySelectorAll( '.js-home-banner a, .m-hero a' );

	if ( heroLinks ) {
		heroLinks.forEach( ( link ) => {
			link.addEventListener( 'click', ( event ) => {
				const name = event.currentTarget.text;

				dataLayer.push( {
					event: 'hero_click',
					hero_details: name.trim(),
				} );
			} );
		} );
	}
};

export const trackHeroCarouselClick = ( data ) => {
	dataLayer.push( {
		event: "carousel_click",
		carousel_details: data.details,
		link_url: data.href,
		link_text: data.name
	} );
};

const trackCarouselClick = () => {
	const $sliderLink = document.querySelectorAll( '.c-card-slider a' );

	if ( $sliderLink ) {
		$sliderLink.forEach( ( link ) => {
			link.addEventListener( 'click', () => {
				const name = link.text?.trim(),
					href = link.href?.trim(),
					carouselEl = link.closest(".c-card-slider"),
					slideEl = link.closest(".n2-ss-slide"),
					carouselName = carouselEl?.getElementsByTagName("h3")[0]?.outerText?.trim() || 'Carousel',
					slideName = slideEl?.querySelectorAll("[id^='n2-ss-']")[0]?.outerText?.trim() || 'Slide',
					slideNumber = slideEl?.dataset?.slidePublicId || '1';

				dataLayer.push( {
					event: 'carousel_click',
					carousel_details: `${carouselName}|${slideName}|${slideNumber}`,
					link_url : href,
					link_text : name
				} );
			} );
		} );
	}
};

export const trackAccordionClick = ( accordionTitle ) => {
	dataLayer.push( {
		event: 'accordion_click',
		accordion_title: accordionTitle,
	} );
};

const trackCompareViewDetailsEvent = () => {
	const $compareDetails = document.querySelectorAll( '.js-compare-bearings-details' );

	if ( $compareDetails ) {
		$compareDetails.forEach( ( $link ) => {
			$link.addEventListener( 'click', () => {
				const data = JSON.parse( $link.getAttribute( 'data-bearing' ) );
				const position = Number( $link.getAttribute( 'data-position' ) );

				dataLayer.push( {
					event: 'product_click',
					item: {
						item_id: data.marketplace_description || '',
						item_name: data.marketplace_description || '',
						item_brand: null,
						index: position,
						item_category: 'bearing',
						item_category2: data.bearing_type,
						item_category3: data.bearing_subtype,
						item_list_name: 'compare bearings',
					},
				} );
			} );
		} );
	}
};

const trackAuthStatus = () => {
	document.addEventListener( 'timken_sso_account_info_loaded', async ( event ) => {
		if ( event.detail.accountInfo ) {
			dataLayer.push( {
				event: 'user_info',
				uid: event.detail.accountInfo.uid ?? '',
				h_id: event.detail.accountInfo.email ? await hashValue( event.detail.accountInfo.email ) : '',
				authentication_status: 'authenticated',
			} );
		} else {
			dataLayer.push( {
				event: 'user_info',
				authentication_status: 'not authenticated',
			} );
		}
	} );
};

async function hashValue( value ) {
	const utf8 = new TextEncoder().encode( value );
	const hashBuffer = await crypto.subtle.digest( 'SHA-256', utf8 );
	const hashArray = Array.from( new Uint8Array( hashBuffer ) );
	const hashHex = hashArray
		.map( ( bytes ) => bytes.toString( 16 ).padStart( 2, '0' ) )
		.join( '' );
	return hashHex;
}

document.addEventListener( 'DOMContentLoaded', () => {
	trackGlobalSearch();

	trackSocialFlowEvent();

	trackTopNavigationEvent();

	trackFooterNavigationEvent();

	trackDownloadEvent();

	trackHeroClickEvent();

	trackEngineeringToolAction();

	trackOffRampLink();

	trackPDPContentLink();

	trackPDPDetailsHeadLink();

	trackHomepageContentLink();

	trackLandingPageLink();

	trackBannerLink();

	trackCarouselClick();

	trackCompareViewDetailsEvent();

	trackAuthStatus();
} );
