import Swiper, { Pagination } from 'swiper';

class PartsDetails {
	constructor( $el ) {
		this.$el = {
			root: $el,
			gallery: $el.querySelector( '.js-parts-image-slider' ),
		};

		this.init();
	}

	init() {
		if ( this.$el.gallery ) {
			this.imageSlider = new Swiper( this.$el.gallery, {
				modules: [ Pagination ],
				centeredSlides: true,
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			} );
		}
	}
}

document.addEventListener( 'DOMContentLoaded', () => {
	const $partsDetailsPage = document.querySelector( '.js-parts-details' );
	if ( $partsDetailsPage ) {
		new PartsDetails( $partsDetailsPage );
	}
} );
